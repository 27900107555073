import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  InputBase,
  IconButton,
  Tooltip,
  Box,
 
  
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { apiRouterCall } from '../../api/services';
import { Pagination, Skeleton } from '@material-ui/lab';
import { FileCopy } from '@material-ui/icons';
import { sortAddress } from '../../Utils';
import TransactionDetails from './TransactionDetails';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#1e1e1e',
    color: '#fff',
  },
  tableHead: {
    backgroundColor: '#333',
  },
  tableCell: {
    color: '#fff',
    border: '1px solid #444',
  },
  oddRow: {
    backgroundColor: '#2b2b2b',
  },
  evenRow: {
    backgroundColor: '#1e1e1e',
  },
  search: {
    margin: '16px 0px',
    padding: '8px',
    backgroundColor: '#333',
    color: '#fff',
    borderRadius: '4px',
    width: '100%',
  },
  title: {
    padding: '16px',
    color: '#fff',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    "& .MuiPaginationItem-root":{
      color:"#fff"
    }
  },
}));

const TransactionsTable = ({ data, isLoading }) => {
  const classes = useStyles();
const[open,setOpen]=useState(false)
const [sourceData,setSourceData]=useState("")
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
    
    <TableContainer component={Paper} className={classes.container}>
      <Typography variant="h6" component="div" className={classes.title}>
        Cross-Chain Transactions
      </Typography>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell}>Coin Type</TableCell>
           
            <TableCell className={classes.tableCell}>Amount</TableCell>
            <TableCell className={classes.tableCell}>From</TableCell>
            <TableCell className={classes.tableCell}>To</TableCell>
            <TableCell className={classes.tableCell}>Date & Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            Array.from(new Array(5)).map((_, index) => (
              <TableRow key={index}>
                {Array.from(new Array(6)).map((_, cellIndex) => (
                  <TableCell key={cellIndex} className={classes.tableCell}>
                    <Skeleton animation="wave" height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            data.map((transaction, index) => (
              <TableRow
              style={{cursor:"pointer"}}
              onClick={()=>{
                setSourceData(transaction?.sourceHash)
               setOpen(true)}}
                key={transaction._id}
                className={index % 2 === 0 ? classes.evenRow : classes.oddRow}
              >
                <TableCell className={classes.tableCell}>
                  {(transaction?.sourceName === "Ethereum" || transaction?.sourceName === "Binance Smart Chain") ? "WTAN" :"TAN"}
                  {(transaction?.sourceName === "Ethereum" || transaction?.sourceName === "Binance Smart Chain") && (
                     <Box display="flex" alignItems="center" onClick={()=>window.open(transaction?.sourceName === "Ethereum"?`https://sepolia.etherscan.io/address/${transaction?.crossChainTokenAddr}`:transaction?.sourceName === "Binance Smart Chain" ? `https://testnet.bscscan.com/address/${transaction?.crossChainTokenAddr}` :"")}>
                     {transaction?.crossChainTokenAddr && sortAddress(transaction?.crossChainTokenAddr)}
                   <Tooltip title="Copy Crosschain Hash">
                     <IconButton
                     style={{padding:'0px 0px 0px 5px'}}
                       onClick={() => handleCopy(transaction?.crossChainTokenAddr)}
                       size="small"
                       color="inherit"
                     >
                       <FileCopy style={{width:"16px"}} />
                     </IconButton>
                   </Tooltip>
                     </Box>

                  )}
                 
                </TableCell>
              
                <TableCell className={classes.tableCell}>
                  {transaction.amount}
                </TableCell>
                <TableCell className={classes.tableCell} 
                // onClick={()=>{
                //    setSourceData(transaction?.sourceHash)
                //   setOpen(true)}}

                  >
                {transaction?.sourceName}
                  <Box display="flex" alignItems="center">
                 
                  {transaction?.sourceHash && sortAddress(transaction?.sourceHash)}
                  <Tooltip title="Copy Source Hash">
                    <IconButton
                       style={{padding:'0px 0px 0px 5px'}}
                      onClick={() => handleCopy(transaction?.sourceHash)}
                      size="small"
                      color="inherit"
                    >
                      <FileCopy style={{width:"16px"}} />
                    </IconButton>
                  </Tooltip>
                  </Box>
                
                </TableCell>
                <TableCell className={classes.tableCell}>
                    {transaction?.destName}
                    <Box display="flex" alignItems="center">
                    {transaction?.destHash && sortAddress(transaction?.destHash)}
                  <Tooltip title="Copy Destination Hash">
                    <IconButton
                       style={{padding:'0px 0px 0px 5px'}}
                      onClick={() => handleCopy(transaction.destHash)}
                      size="small"
                      color="inherit"
                    >
                      <FileCopy style={{width:"16px"}} />
                    </IconButton>
                  </Tooltip>
                    </Box>
                 
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {new Date(transaction.timeStamp * 1000).toLocaleString()}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {!isLoading && data && data.length===0 && (
      <Typography style={{textAlign:"center",paddingTop:"10px"}}>Data Not Found</Typography>
    )}
    {open && (
        <TransactionDetails sourceData={sourceData} open={open} setOpen={(item)=>{
         
          
          setOpen(item)
        }}  />
    )}
    </>
  );
};

const Explorer = () => {
  const [search, setSearch] = useState('');
  const [transactionData, setTransactionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getSwapHistoryData = async (page) => {
    setIsLoading(true);
    try {
      const res = await apiRouterCall({
        method: 'GET',
        endPoint: 'findAllSwapData',
        params: {
          sourceHash: search || undefined,
          page, // Pass current page for pagination
        },
      });
      if (res.data.data) {
        setTransactionData(res.data.data);
        setTotalPages(res.data.totalPages); // Assuming backend returns total pages
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSwapHistoryData(currentPage);
  }, [search, currentPage]);

  const handlePageChange = (_, newPage) => {
    setCurrentPage(newPage);
  };

  const classes = useStyles();

  return (
    <div style={{marginTop:"30px"}}>
      <InputBase
        placeholder="Search by Source Hash"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className={classes.search}
        fullWidth
      />
      
      <TransactionsTable data={transactionData} isLoading={isLoading} />
      {!isLoading && transactionData && transactionData?.length>0 && (
        <div className={classes.paginationContainer}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
      )}
      
    </div>
  );
};

export default Explorer;
