
export const baseURL = "https://stgapi-bridge.tarality.io"; // staging_url New
// export const baseURL = "http://172.16.16.27:1970"; // staging_url New

let version = "api/v1"; //v0

const apiConfigs = {
  findAllSwapData: `${baseURL}/${version}/swap/findAllSwapData`,
  viewSawpData: `${baseURL}/${version}/swap/viewSawpData`,



};

export default apiConfigs;
