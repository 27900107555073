import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Link,
  IconButton,
  Box,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { sortAddress } from "../../Utils";
import { apiRouterCall } from "../../api/services";
import { Skeleton } from "@material-ui/lab";
import { FileCopy } from "@material-ui/icons";
import toast from "react-hot-toast";

// Styling for the grid and paper components
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: "rgba(0, 0, 0, 0.87)", // Dark background
  },
  addressLink: {
    color: "#BB86FC", // Light purple for addresses
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.87)", // Dark background for the title
    color: "#ffffff", // White text for title
  },
  typographyKey: {
    color: "#ffffff", // White text for keys
    fontSize: "0.875rem", // Smaller font size for keys
  },
  typographyValue: {
    color: "#ffffff", // White text for values
    fontSize: "1rem", // Larger font size for values
  },
  dialogContent: {
    backgroundColor: "rgba(0, 0, 0, 0.87)", // Dark background for content
    color: "#ffffff", // White text inside the content
  },
}));

const TransactionDetails = ({ open, setOpen, sourceData }) => {
  const classes = useStyles();
  const [data, setHashData] = useState({});
  const [loading, setLoading] = useState(true);

  // get particular hash data
  const getHashData = async (source) => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        endPoint: "viewSawpData",
        params: {
          sourceHash: source,
        },
      });
      if (res.data) {
        setHashData(res.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sourceData) {
      setLoading(true); // Start loading when sourceData changes
      getHashData(sourceData);
    }
  }, [sourceData]);

  const renderDataOrSkeleton = (dataValue) => {
    return loading ? <Skeleton width={200} /> : dataValue;
  };

  // Function to make addresses clickable
  const renderAddressLink = (address,name,txAdd) => {
    console.log(">>>>>>>>>>txAdd",txAdd);
    const handleCopy = () => {
      navigator.clipboard.writeText(address);
      toast.success("Copied")
      console.log("Address copied:", address);
    };

    const blockExplorerUrl =txAdd==="TANTX" ? `https://tanledger.com/tx/${address}`: txAdd==="BNBTX" ? `https://testnet.bscscan.com/tx/${address}` : txAdd==="ETHTX" ? `https://sepolia.etherscan.io/tx/${address}`:  name==="Binance Smart Chain"? `https://testnet.bscscan.com/address/${address}`:name==="Ethereum"? `https://sepolia.etherscan.io/address/${address}`:`https://tanledger.com/address/${address}`;
    return (
      <Box display="flex" alignItems="center">
        <Link
          href={blockExplorerUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.addressLink}
        >
          {sortAddress(address)}
        </Link>
        <Tooltip title="Copy Address">
          <IconButton style={{padding:'0px 0px 0px 5px'}} className={classes.copyIcon} onClick={handleCopy}>
            <FileCopy fontSize="small" style={{color:"#fff",width:"16px"}} />
          </IconButton>
        </Tooltip>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogTitle className={classes.dialogTitle}  >
      

   Transaction Details
      
       
      </DialogTitle>
      <Box style={{position:'absolute',right:'20px'}}>
      <IconButton
          edge="end"
          color="inherit"
          onClick={() => setOpen(false)}
          aria-label="close"
        >
          <CloseIcon style={{color:"#fff"}} />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Grid container spacing={3}>
          {/* Source Hash */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              Source Hash
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(
                data?.sourceHash ? renderAddressLink(data?.sourceHash,data?.sourceName, (data?.destName==="Binance Smart Chain"||data?.destName==="Ethereum" ? "TANTX" : data?.sourceName==="Binance Smart Chain" ? "BNBTX" : data?.sourceName==="Ethereum" ? "ETHTX":""  )) : ""
              )}
            </Typography>
          </Grid>

          {/* Destination Hash */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              Dest Hash
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(
                data?.destHash ? renderAddressLink(data?.destHash,data?.destName,(data?.destName==="Binance Smart Chain" ? "BNBTX" : data?.destName==="Ethereum" ? "ETHTX":"TANTX")) : ""
              )}
            </Typography>
          </Grid>

          {/* Source Name */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              Source Chain
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(data.sourceName)}
            </Typography>
          </Grid>

          {/* Destination Name */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              Dest Chain
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(data.destName)}
            </Typography>
          </Grid>

          {/* Cross Chain Token Address */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              From
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(
                data?.toAddress
                  ? renderAddressLink(data?.toAddress,data?.sourceName)
                  : ""
              )}
            </Typography>
          </Grid>

          {/* To Address */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              To
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(
                data?.toAddress ? renderAddressLink(data?.toAddress,data?.destName) : ""
              )}
            </Typography>
          </Grid>

          {/* Created At */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              Date
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(
                new Date(data.createdAt).toLocaleString()
              )}
            </Typography>
          </Grid>

          {/* Amount */}
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.typographyKey}>
              Amount
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.typographyValue}>
              {renderDataOrSkeleton(data.amount)}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionDetails;
